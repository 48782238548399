export const useFBQ = () => {
  const registerEvent = (eventName, data) => {
    if (process.env.NODE_ENV === 'production') {
      if (data) {
        fbq('track', eventName, data);
      } else {
        fbq('track', eventName);
      }
    }
  }

  return {
    registerEvent,
  }
}