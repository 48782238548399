import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch-text" }

import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "@/store";


export default {
  __name: 'ThemeSwitch',
  setup(__props) {

const THEME_LIST = ['light', 'dark'];

const settingsStore = useSettingsStore();
const { theme } = storeToRefs(settingsStore);

const nextTheme = computed(() => {
  const index = THEME_LIST.indexOf(theme.value);
  if (index + 1 > THEME_LIST.length - 1) {
    return THEME_LIST[0];
  } else {
    return THEME_LIST[index + 1];
  }
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`game-switch ${_unref(theme)}`),
    onClick: _cache[0] || (_cache[0] = $event => (_unref(settingsStore).setTheme(nextTheme.value)))
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "switch-icon" }, null, -1)),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(`theme.${nextTheme.value}`).toUpperCase()), 1)
  ], 2))
}
}

}